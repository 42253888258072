<template>
  <v-container
    fluid
    class="primary"
  >
    <v-row class="ml-5 pt-3">
      <v-tooltip
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="getRan()"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >search</v-icon>
            <span class="accent--text text-h6 text-capitalize">New Random</span>
          </v-btn>
        </template>
        <span>Create Random Photos</span>
      </v-tooltip>
    </v-row>
    <v-row
      class="mb-5"
      :key="refresh"
    >
      <v-flex
        sm12
        md12
        lg6
        text-center
        headline
        mt-16
        ml-10
      >
        <v-carousel
          transition="fade-transition"
          height="auto"
          class="img-one"
          progress-color="red"
          prev-icon="arrow_left"
          next-icon="arrow_right"
          delimiter-icon="stop"
          hide-delimiters
        >
          <v-carousel-item
            aspect-ration="4/3"
            v-for="project in projects"
            :key="project.id"
            :src="picurl + project.pic"
          >
            <v-layout
              fill-height
              align-end
            >
              <v-flex xs12>
                <!-- <div class="tag1 ml-2 black--text">
                  <span class="pl-2 pr-2 white"> Id: {{ project.id }} </span>
                </div> -->
                <div
                  v-if="project.tag"
                  class="tag2 white--text black"
                >
                  <span
                    class="pl-2 pr-2"
                    v-html="project.tag"
                  >
                  </span>
                </div>
                <div
                  v-if="project.rating > 0"
                  class="tag3 black--text"
                >
                  <span class="pl-2 pr-2 white">
                    Rating: {{ project.rating }}
                  </span>
                </div>
              </v-flex>
            </v-layout>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
      <v-col
        lg="3"
        xl="3"
      >
        <div
          @click="changeAlb(album.album)"
          class="mt-12 ml-10 album success--text headline"
          v-for="album in albums"
          :key="album.id"
        >
          <p
            class="accent--text"
            v-if="album.album == search"
          >
            {{ album.album }} ({{ projects.length }})
          </p>
          <p
            class="success--text"
            v-if="album.album != search"
          >
            {{ album.album }}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="ml-10 mt-5 mb-5">
      <v-btn
        v-if="search"
        small
        text
        color="success"
        @click="delImgs()"
      >
        <v-icon
          small
          left
        >delete</v-icon>
        <span class="success--text text-lowercase text-h6">Delete Album</span>
      </v-btn>
    </v-row>
    <v-row v-if="upimg">
      <v-col>
        <input
          class="ml-3 subtitle-1"
          type="file"
          id="file"
          ref="file"
          v-on:change="onChangeFileUpload()"
        />
        <v-btn
          top
          color="amber"
          class="subtitle-1"
          v-on:click="submitForm()"
        >Upload</v-btn>
        <v-btn
          top
          color="amber"
          class="subtitle-1 ml-2"
          v-on:click="upimg = false"
        >Cancel</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { shared } from "../mixins/shared";
import store from "../store/store.js";

export default {
  mixins: [shared],
  props: {
    tid: {
      type: String,
      required: true,
      default: "images",
    },
  },
  data() {
    return {
      projects: [],
      albums: [],
      tags: [],
      names: [],
      add_pic: "",
      ratings: 0,
      edittag: "",
      picurl: "https://vsgpartners.com/images/",
      url: "https://vsgpartners.com/data.php",
      picdel: "/images/",
      upimg: false,
      addel: 0,
      newalb: "",
      albdel: "",
      rename: "",
      search: "",
      refresh: 0,
      cur_id: 0,
    };
  },
  methods: {
    getRan() {
      this.delRand();
      var action = "random";
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: action,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {
          this.search = "Random";
          this.getDocs();
        })
        .catch((error) => alert(error));
    },
    delImg() {
      this.addel = prompt("Please enter the id to delete");
      if (this.addel > 0) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "delimg",
          id: this.addel,
          picdel: this.picdel,
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then(alert("Photo deleted"));
        this.forceRef();
      }
    },
    async editTag() {
      this.addel = prompt("Please enter the id to edit");
      if (this.addel > 0) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "edittag",
          id: this.addel,
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((res) => {
            const data = res.data;
            this.tags = data;
            this.saveTag();
          });
      }
    },
    async getRating() {
      this.addel = prompt("Please enter the id to rate");
      if (this.addel > 0) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "getrating",
          id: this.addel,
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((res) => {
            const data = res.data;
            this.ratings = data;
            this.saveRating();
          });
      }
    },
    async saveTag() {
      var def = this.tags[0].tag;
      this.edittag = prompt("Please enter tag", def);
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "savetag",
        id: this.addel,
        tag: this.edittag,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {
          alert("Tag Updated");
          this.getDocs();
          this.getAlbs();
        });
    },
    async saveRating() {
      var def = this.ratings[0].rating;
      this.editrating = prompt("Please enter rating", def);
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "saverating",
        id: this.addel,
        rating: this.editrating,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {
          alert("Rating Updated");
          this.getDocs();
          this.getAlbs();
        });
    },
    delImgs() {
      this.albdel = prompt("Please enter the album to delete");
      if (this.albdel == this.search) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "delimgs",
          id: this.albdel,
          picdel: this.picdel,
          tid: this.tid,
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then(() => {
            alert("Album Deleted");
            this.getAlbs();
          });
      }
    },
    delRand() {
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "delimgs",
        id: "Random",
        picdel: this.picdel,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(alert("Album deleted"));
      this.getAlbs();
    },
    fileName() {
      this.addel = prompt("Please enter the id for photo");
      if (this.addel > 0) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "filename",
          id: this.addel,
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then((res) => {
            const data = res.data;
            this.names = data;
            alert("The filename is " + this.names[0].pic);
          });
      }
    },
    reName() {
      var old_name = this.search;
      this.rename = prompt("Please enter new name");
      if (this.search && this.rename) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "rename",
          id: old_name,
          name: this.rename,
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then(() => {
            this.search = this.rename;

            this.forceRerender();
          });
      }
    },
    changeAlb(album) {
      this.search = album;
      this.getDocs();
      // this.forceRerender();
    },
    newAlb() {
      this.newalb = prompt("Please enter the album title");
      if (this.newalb) {
        this.search = this.newalb;
        this.getDocs();
        // this.forceRef();
      }
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    submitForm() {
      var check = document.getElementById("file");
      if (check.value == "") {
        alert("Must choose a file");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.file);
      this.axios
        .post("https://vsgpartners.com/api.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          alert("Photo added");
          this.upimg = false;
          this.storeImg(this.file.name);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    loadImg(id) {
      this.upimg = true;
    },
    storeImg(img) {
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "addpic",
        img: img,
        album: this.search,
        tid: this.tid,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          this.getDocs();
          this.getAlbs();
          this.forceRerender();
        })
        .catch((error) => alert(error));
    },
    async getAlbs() {
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "displayalb",
        search: this.search,
        tid: this.tid,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.albums = data;
        })
        .catch((error) => alert(error));
    },
    async getDocs() {
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "displayimg",
        search: this.search,
        tid: this.tid,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.projects = data;
        })
        .catch((error) => alert(error));
    },
  },
  created() {
    var url = this.url;
    var db = store.durl;
    var formdata = {
      action: "displayimg",
      search: "Random",
      tid: this.tid,
      db: db,
    };
    this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        const data = res.data;
        this.projects = data;
        this.getAlbs();
      })
      .catch((error) => alert(error));
  },
};
</script>

<style scoped>
.tag1 {
  float: left;
  width: 20%;
}

.tag2 {
  float: left;
  width: 100%;
}

.tag3 {
  float: left;
  width: 10%;
}

.img-one {
  border-radius: 10%;
}

.img-two {
  border-radius: 10%;
  width: 15vw;
  height: 15vw;
  object-fit: cover;
}

.idinfo {
  float: left;
  position: fixed;
  left: 10%;
  bottom: 30%;
  font-size: 1.5rem;
}

.tag {
  float: left;
  position: fixed;
  left: 12%;
  bottom: 30%;
  font-size: 1.5rem;
  padding-left: 1%;
  padding-right: 1%;
}

.grad {
  background-image: linear-gradient(to bottom right, #ffd600, #e65100);
}

.album {
  cursor: pointer;
}

.album:hover {
  color: orange !important;
}
</style>
